import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'


Vue.use(Router);

function lazyLoad(view){
    return() => import(`@/views/${view}.vue`)
}



let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'homepage',
            component: lazyLoad("homepage"),
        },
        {
            path: '/pages',
            name: 'pages',
            component: lazyLoad("pages/pages"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: ':id',
                    component: lazyLoad("pages/pages-edit"),
                    name: 'pagesEdit',
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'parts/:id',
                    component: lazyLoad("pages/pages-parts-edit"),
                    name: 'pagesPartsEdit' ,
                    meta: {
                        requiresAuth: true
                    }
                },
            ],
        },
        {
            path: '/:lang/page/:id',
            name: 'page',
            component: lazyLoad("page")
        },
        {
            path: '/users',
            name: 'users',
            component: lazyLoad("users/users"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: ':id',
                    component: lazyLoad("users/users-edit"),
                    name: 'usersEdit',
                    meta: {
                        requiresAuth: true
                    }
                },
            ],
        },
        {
            path: '/user-edit/:id',
            name: 'userEdit',
            component: lazyLoad("users/users-edit"),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/meetings',
            name: 'meetings',
            component: lazyLoad("meetings/meetings"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: ':id',
                    component: lazyLoad("meetings/meetings-edit"),
                    name: 'meetingsEdit',
                    meta: {
                        requiresAuth: true
                    }
                },
            ],
        },
        {
            path: '/projects',
            name: 'projects',
            component: lazyLoad("projects/projects"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: ':id',
                    component: lazyLoad("projects/projects-edit"),
                    name: 'projectsEdit',
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/attachments/:id',
                    component: lazyLoad("projects/projects-attachments"),
                    name: 'projectsAttachments',
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/preview/:id',
                    component: lazyLoad("projects/projects-preview"),
                    name: 'projectsPreview',
                    meta: {
                        requiresAuth: true
                    }
                },
            ],

        },
        { /* delete it on production */
            path: '/test',
            name: 'test',
            component: lazyLoad("test/test"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: ':id',
                    component: lazyLoad("test/test-edit"),
                    name: 'testEdit',
                    meta: {
                        requiresAuth: true
                    }
                },
            ],
        },
        {
            path: '/settings',
            name: 'settings',
            component: lazyLoad("settings"),
        },
        {
            path: '/password-reset/:id?',
            name: 'password-reset',
            component: lazyLoad("password-reset"),
        },
        {
            path: '/registration',
            name: 'registration',
            component: lazyLoad("registration"),
        },
        {
            path: '/registration-confirm/:id',
            name: 'registration-confirm',
            component: lazyLoad("registration-confirm"),
        },

        {
            path: '*',
            name: '',
            component: lazyLoad("homepage"),
        }
    ]
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLogged) {
            next();
            return
        }
        next('/')
    } else {
        next()
    }
});

export default router
