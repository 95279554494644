<template>

    <b-navbar toggleable="xl" type="light" class="c-navbar" sticky>
        <b-container>
            <b-navbar-brand>
                <router-link class="nav-link" :to="{name: (isLoggedIn ? 'projects' : 'homepage')}">
                    <img class="c-navbar__logo" :src=" path + '/assets/img/logo.svg'" alt="logo">
                </router-link>
            </b-navbar-brand>
    
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    
            <b-collapse id="nav-collapse" class="ml-1" is-nav>
                <b-navbar-nav>
                    <template v-if="isLoggedIn">
                        <b-nav-item v-if="role !== 'meetings-only'" to="/projects">{{this.$t('navbar.projects')}}</b-nav-item>

                        <b-nav-item v-if="role == 'admin' || role == 'meetings-only'" to="/meetings">{{this.$t('navbar.meetings')}}</b-nav-item>
    
                        <b-nav-item v-if="isAdmin" to="/users">{{this.$t('navbar.users')}}</b-nav-item>

                        <b-nav-item v-if="isAdmin" to="/pages">{{this.$t('navbar.pages')}}</b-nav-item>

                    </template>
                    

                    <b-nav-item v-if="!isAdmin" :href="'/' + $i18n.locale + '/page/o-programu'">{{this.$t('navbar.about')}}</b-nav-item>
                    <b-nav-item v-if="!isAdmin" :href="'/' + $i18n.locale + '/page/vyzvy'">{{this.$t('navbar.calls')}}</b-nav-item>
                    <b-nav-item v-if="!isAdmin" :href="'/' + $i18n.locale + '/page/zadatel'">{{this.$t('navbar.applicant')}}</b-nav-item>
                    <b-nav-item v-if="!isAdmin" :href="'/' + $i18n.locale + '/page/prijemce'">{{this.$t('navbar.recipient')}}</b-nav-item>
                    <b-nav-item v-if="!isAdmin" :href="'/' + $i18n.locale + '/page/dokumenty'">{{this.$t('navbar.documents')}}</b-nav-item>
                    <b-nav-item v-if="!isAdmin" :href="'/' + $i18n.locale + '/page/hodnotici-komise'">{{this.$t('navbar.monitoring')}}</b-nav-item>
                    <b-nav-item v-if="!isAdmin" :href="'/' + $i18n.locale + '/page/kontakty'">{{this.$t('navbar.contacts')}}</b-nav-item>

                    
                </b-navbar-nav>
    
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item @click="changeLocalization(newLang)" >
                        {{ newLang }}
                    </b-nav-item>
                    <b-nav-item v-if="isAdmin" to="/settings" title="Nastavení">
                        <b-icon class="clickable ml-1"
                                icon="gear-fill" variant=""
                        ></b-icon>
                    </b-nav-item>
                    <div v-if="isAdmin" class="nav-item" >
                        <router-link class="nav-link" :to="{name: 'userEdit', params: {id: userData.id}}">
                            <span>
                                <b-icon class="clickable ml-1"
                                        icon="person-fill" variant=""
                                ></b-icon>
                                {{userData.name}}
                            </span>
                        </router-link>
                    </div>
                    <b-nav-item v-if="isLoggedIn" @click="logout()">
                        <b-icon :title="$t('universalTxt.logout')"
                                class="clickable mb-1"
                                icon="box-arrow-right" variant=""
                        ></b-icon>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            
        </b-container>
    </b-navbar>

</template>

<script>
    export default {
        name: 'the-navbar',
        data() {
            return {
                path: process.env.VUE_APP_SERVER,
                newLang: (JSON.parse(localStorage.getItem('local')) == 'cs') ? 'pl' : 'cs'
            }
        },
        computed : {
            isLoggedIn: function () {
                return this.$store.getters.isLogged
            },
            userData: function () {
                try {
                    return this.$store.getters.user
                } catch (err) {
                    return false
                }
                
            },
            isAdmin: function () {
                try {
                    return (this.$store.getters.user.permission == 'admin') ? true : false
                } catch (err) {
                    return false
                }
            },
            role () {
              try {
                return this.$store.getters.user.permission;
              } catch (err) {
                return false
              }
            },
            
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/')
                    })
            },
            changeLocalization: function (newLang) {
                this.$i18n.locale = newLang;
                localStorage.setItem('local', JSON.stringify(newLang));
                this.newLang = ((newLang) == 'cs') ? 'pl' : 'cs';
                this.$store.dispatch('setLocalizationInServer', newLang);
            }
        },
    }
</script>

<style  lang="scss">

</style>
