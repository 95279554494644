import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(require('vue-moment'));
Vue.use(VueI18n);

VueClipboard.config.autoSetContainer = true; // vue clipboard
Vue.use(VueClipboard);

Vue.prototype.$eventHub = new Vue();

axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');


axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  let originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) { // if the error is 401 and hasent already been retried

    localStorage.removeItem('user');

    if (process.env.NODE_ENV !== 'development') {
      window.location = '/';
    } else {
      window.location = '/';
    }

  }

  // Do something with response error
  return Promise.reject(error)
});
