<template>
    <div class="c-footer">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center mb-5" >
                    <b v-html="$t('footer.text')"></b>
                </div>
                <div class="col-12">
                    <b-row class="align-items-center c-footer__row">
                        <div class="col-6 col-md-2 d-flex justify-content-center">
                            <img :src=" path + 'assets/img/Vlajka_PL.png'" alt="polsko">
                        </div>
                        <div class="col-6 col-md-2 d-flex justify-content-center">
                            <img :src=" path + 'assets/img/Vlajka_CR.png'" alt="česko">
                        </div>
                        <div class="col-12 col-md-4 d-flex justify-content-center c-footer__logo">
                            <img :src=" path + 'assets/img/logo-negativ.svg'"  alt="euroregion">
                        </div>
                        <div class="col-6 col-md-2 d-flex justify-content-center">
                            <img :src=" path + 'assets/img/Vlajka_DW.png'" alt="logo">
                        </div>
                        <div class="col-6 col-md-2 d-flex justify-content-center">
                            <img :src=" path + 'assets/img/Vlajka_LBK.png'" alt="logo">
                        </div>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "the-footer",
    data() {
        return {
            path: process.env.VUE_APP_SERVER,
        }
    }
}
</script>

<style scoped lang="scss">
    .c-footer {
        width: 100%;
        background-color: #00677A;
        color: white;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 40px;
        a {
            color: white;
            &:hover {
                color: white;
             }
        }
        &__row {
            img {
                margin: 10px;
            }
        }
        &__logo {
            @media (max-width: 768px) {
                order: -1;
            }
            img {
                max-width: 230px;
                margin: 20px;
            }
        }
    }
</style>
