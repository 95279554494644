import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

var lang;

if (JSON.parse(localStorage.getItem('local'))) {
	lang = JSON.parse(localStorage.getItem('local'));
} else {
	lang = (window.navigator.language) ? window.navigator.language : 'cs';
	if (lang !== 'cs' && lang !== 'pl') {
		lang = 'cs'
	}
	localStorage.setItem('local', JSON.stringify(lang));
}

const messages = {
	cs: {
		title: 'Fond malých projektů Turów',
		universalTxt: {
			closeBtn: 'Zavřít',
			saveBtn: 'Uložit',
			delete: 'Smazat',
			logout: 'Odhlásit',
			edit: 'Editace',
			edit_final: 'Editace závěrečné zprávy'
		},
		homepage: {
			registration_q: 'Nejste registrovaní?',
			register: 'Registrujte se',
		},
		passwordReset: {
			title: 'Obnova hesla',
			message: 'Vyplňte svůj registrační e-mail a my vám na něj pošleme nové heslo.',
			reset: 'Obnovit heslo',
			change: 'Změnit heslo',
			passwdTest: 'Ověření hesla',
			passwd: 'Heslo',
			login: 'Přihlaste se'
		},
		login: {
			title: 'Přihlaste se',
			login: 'Přihlásit',
			passwdForget: 'Zapomněli jste heslo?'
		},
		registration: {
			reg: 'Registrujte se',
			email: 'E-mail',
			pswd: 'Heslo',
			pswdTest: 'Ověření hesla',
			regBtn: 'Registrovat',
			login: 'Jste již registrovaní a chcete se přihlásit?'
		},
		registrationConfirm: {
			login: 'Přihlaste se'
		},
		projects: {
			title: 'Projekty',
			newProject: 'Založit nový projekt',
			downloadBtn: 'Exportovat do PDF',
			attachments: 'Přílohy',
			infoAboutUploadFile: 'Je povoleno nahrát soubory následujícího typu: image/*, .pdf, .xlsx, .docx, .xls, .ppt, .doc, .odt, .ods, .rar, .zip.',
			maxFileSize: 'Maximální velikost nahrávaných souborů je <b>256 MB</b>.',
			fileCompress: 'Objemné soubory dostatečně zkomprimujte do *.zip, nebo *.rar souboru.',
			phase_1: '1.) Vyplňte formulář',
			phase_2: '2.) Stáhněte vyplněný formulář',
			phase_3: '3.) Nahrajte přílohy včetně elektronicky podepsaného formuláře',
			phase_4: '4.) Odešlete projekt ke kontrole',
			phase_2_1: '1.) Vyplňte formulář závěrečné zprávy',
			phase_2_2: '2.) Stáhněte vyplněný formulář',
			phase_2_3: '3.) Nahrajte závěrečnou zprávu do příloh',
			phase_2_4: '4.) Odešlete závěrečnou zprávu ke kontrole',
			checkProjectBtn: 'Odeslat ke kontrole',
			checkProjectBtn1: 'Odeslat ke 2. kontrole',
			checkProjectBtn2: 'Odeslat ke 3. kontrole',
			messageSend: 'Formulář byl odeslán ke kontrole',
			deleteProject: 'Opravdu smazat?',
			tableColDate: 'Datum',
			tableColCall: 'Výzva č.',
			tableColName: 'Název',
			tableColUserName: 'Žadatel',
			tableColState: 'Stav',
			documents: 'Projektová dokumentace',
			confirmText: 'Žádost o dotaci bude nyní podána a odeslána ke kontrole. Po odeslání ke kontrole už nebude možná editace žádosti o dotaci. Přejete si pokračovat?'
		},
		preview: {
			stateLabel: 'Stav projektu',
			logHistory: 'Historie stavů',
			stateLabelAdmin: 'Admin',
			stateLabelUser: 'User',
			changeState: 'Opravdu změnit stav?',
		},
		statesBtn: {
			registered: 'Uživatel zaregistroval žádost',
			correction_1: 'Žádost o podporu vrácena k dopracování',
			fix_1: 'Uživatel uložil opravu žádosti',
			correction_2: 'Žádost o podporu vrácena k 2. dopracování',
			fix_2: 'Uživatel uložil 2. opravu žádosti',
			accepted: 'Žádost o podporu splnila kontrolu formálních náležitostí a přijatelnosti',
			refused_formal: 'Žádost o podporu nesplnila kritéria formálních náležitostí nebo přijatelnosti; administrace žádosti o podporu je ukončena',
			refused: 'Projekt nebyl Komisí schválen k podpoře, administrace žádosti o podporu je ukončena',
			running: 'Projekt je v realizaci',
			p2_registered: 'Závěrečná zpráva o realizaci projektu zaregistrována',
			p2_correction_1: 'Závěrečná zpráva o realizaci projektu vrácena k dopracování',
			p2_fix_1: 'Opravená Závěrečná zpráva o realizaci projektu čeká na posouzení',
			p2_refused: 'Závěrečná zpráva o realizaci projektu zamítnuta, administrace Závěrečné zprávy o realizaci projektu je ukončena.',
			p2_accepted: 'Závěrečná zpráva přijata',
			p2_finished: 'Projekt je finančně ukončen.'
		},
		statesShortcut: {
			all_filter: 'Stav (vše)',
			registered: 'Zaregistrováno',
			correction_1: 'Vráceno k 1. dopracování',
			fix_1: 'Opravená žádost čeká',
			correction_2: 'Vráceno k 2. dopracování',
			fix_2: 'Opravená žádost 2 čeká',
			accepted: 'Formálně přijata',
			refused_formal: 'Zamítnuto z hlediska formálních náležitostí a přijatelnosti',
			refused: 'Komísí neschválen',
			running: 'V realizaci',
			p2_registered: 'Závěrečná zpráva zaregistrována',
			p2_correction_1: 'Závěrečná zpráva vrácena k dopracování',
			p2_fix_1: 'Opravená Závěrečná zpráva čeká na posouzení',
			p2_refused: 'Závěrečná zpráva definitivně zamítnuta',
			p2_accepted: 'Závěrečná zpráva přijata',
			p2_finished: 'Projekt je finančně ukončen'
		},
		footer: {
			text: 'Euroregion Nisa<br>Fond malých projektů Turów'
		},
		settings: {
			title: 'Nastavení',
			number: 'Číslo výzvy',
		},
		messages: {
			success: 'Změna proběhla úspěšně',
			error: 'Operace se nezdařila',
			file_uploaded: 'Soubor byl úspěšně nahrán.',
			deleted: 'Položka byla smazána',
			wrong_login: 'Účet neexistuje, nebo nebyl aktivován ve vaší e-mailové schránce.',
			pswd_not_same: 'Hesla se neschodují',
			pswd_empty: 'Heslo nesmí být prázdné'
		},
		navbar: {
			users: 'Uživatelé',
			meetings: 'Zasedání Komise',
			projects: 'Projekty',
			pages: 'Stránky',
			documents: 'Dokumenty',
			contacts: 'Kontakty',
			monitoring: 'Hodnotící komise',
			about: 'O programu',
			recipient: 'Příjemce',
			calls: 'Výzvy',
			applicant: 'Žadatel'
		},
		users: {
			title: 'Uživatelé',
			name: 'Jméno',
			email: 'E-mail',
			tel: 'Telefon',
			permissions: 'Oprávnění',
			changePswd: 'Změnit heslo?',
			newPswd: 'Nové heslo',
			newPswdControl: 'Potvrzení nového hesla',
			role: {
				admin : 'Admin',
				user1 : 'Žadatel',
				'meetings-only' : 'Člen komise',
			}
		},
		meetings: {
			title: 'Zasedání Komise',
			tableDateCol: 'Datum zasedání',
			tableName: 'Název zasedání',
			addMeeting: 'Přidat zasedání',
			attachments_cs: 'Přílohy pro české členy Hodnotící komise',
			attachments_pl: 'Přílohy pro polské členy Hodnotící komise',
		},
	},
	pl: {
		title: 'Fundusz Małych Projektów Turów',
		universalTxt: {
			closeBtn: 'Zamknij',
			saveBtn: 'Zapisz',
			delete: 'Usuń',
			logout: 'Wyloguj się',
			edit: 'Edytuj',
			edit_final: 'Edycja raportu końcowego'
		},
		homepage: {
			registration_q: 'Nie jesteś zarejestrowany?',
			register: 'Zarejestruj się',
		},
		passwordReset: {
			title: 'Odzyskiwanie hasła',
			message: 'Wpisz swój e-mail rejestracyjny, a my wyślemy Ci nowe hasło.',
			reset: 'Odzyskaj hasło',
			change: 'Zmień hasło',
			passwdTest: 'Powtórz hasło',
			passwd: 'Hasło',
			login: 'Zaloguj się'
		},
		login: {
			title: 'Zaloguj się',
			login: 'Logowanie',
			passwdForget: 'Zapomniane hasło'
		},
		registration: {
			reg: 'Zarejestruj się',
			email: 'E-mail',
			pswd: 'Hasło',
			pswdTest: 'Powtórz hasło',
			regBtn: 'Rejestracja',
			login: 'Jesteś już zarejestrowany i chcesz się zalogować?'
		},
		registrationConfirm: {
			login: 'Zaloguj się'
		},
		projects: {
			title: 'Projekty',
			newProject: 'Wygeneruj nowy projekt',
			downloadBtn: 'Eksport do PDF',
			attachments: 'Załączniki',
			infoAboutUploadFile: 'Dozwolone jest wgrywanie następujących typów plików: image/*, .pdf, .xlsx, .docx, .xls, .ppt, .doc, .odt, .ods, .rar, .zip.',
			maxFileSize: 'Maksymalna wielkość wgrywanych plików to <b>256 MB</b>.',
			fileCompress: 'Duże pliki należy odpowiednio skompresować do pliku *.zip lub *.rar.',
			phase_1: '1.) Wypełnij formularz',
			phase_2: '2.) Pobierz wypełniony formularz',
			phase_3: '3.) Wczytaj załączniki, w tym podpisany elektronicznie formularz',
			phase_4: '4.) Wyślij projekt do kontroli',
			phase_2_1: "1.) Wypełnij formularz raportu końcowego",
			phase_2_2: '2.) Pobrać wypełniony formularz',
			phase_2_3: '3.) Prosimy o wgranie raportu końcowego do załączników',
			phase_2_4: '4.) Wyślij raport końcowy do kontroli',
			checkProjectBtn: 'Wyślij do kontroli',
			checkProjectBtn1: 'Wyślij do 2. kontroli',
			checkProjectBtn2: 'Wyślij do 3. kontroli',
			messageSend: 'Formularz został wysłany do kontroli',
			deleteProject: 'Czy naprawdę chcesz usunąć?',
			tableColDate: 'Data',
			tableColCall: 'Nabór nr',
			tableColName: 'Tytuł',
			tableColUserName:'Wnioskodawca',
			tableColState: 'Status',
			documents: 'Dokumentacja projektowa',
			confirmText: 'Wniosek o dofinansowanie zostanie teraz złożony i wysłany do kontroli.  Po wysłaniu do kontroli nie będzie już możliwości edytowania wniosku o dofinansowanie. Czy chcesz kontynuować?'
		},
		preview: {
			stateLabel: 'Status projektu',
			logHistory: 'Historia statusów',
			stateLabelAdmin: 'Admin',
			stateLabelUser: 'Użytkownik',
			changeState: 'Czy naprawdę chcesz zmienić status?',
		},
		statesBtn: {
			registered: 'Użytkownik zarejestrował wniosek',
			correction_1: 'Wniosek o dofinansowanie zwrócony do poprawy',
			fix_1: 'Użytkownik zapisał poprawiony wniosek',
			correction_2: 'Wniosek o dofinansowanie zwrócony do 2. poprawy',
			fix_2: 'Użytkownik zapisał 2. wersję poprawionego wniosku',
			accepted: 'Wniosek o dofinansowanie przeszedł pozytywnie kontrolę wymogów formalnych i kwalifikowalności',
			refused_formal: 'Wniosek o dofinansowanie nie spełnił kryteriów formalnych lub kwalifikowalności; procedowanie wniosku o dofinansowanie jest zakończone',
			refused: 'Projekt nie został przez Komisję zatwierdzony do dofinansowania, procedowanie wniosku o dofinansowanie jest zakończone',
			running: 'Projekt jest w trakcie realizacji',
			p2_registered: 'Raport końcowy z realizacji projektu został zarejestrowany',
			p2_correction_1: 'Raport końcowy z realizacji projektu został zwrócony do poprawy',
			p2_fix_1: 'Poprawiony raport końcowy z realizacji projektu oczekuje na rozpatrzenie',
			p2_refused: 'Raport końcowy z realizacji projektu został odrzucony, obsługa administracyjna raportu końcowego z realizacji projektu została zakończona.',
			p2_accepted: 'Raport końcowy został przyjęty',
			p2_finished: 'Projekt jest zakończony pod względem finansowym.'
		},
		statesShortcut: {
			all_filter: 'Status (wszystkie)',
			registered: 'Zarejestrowany',
			correction_1: 'Zwrócony do 1. poprawy',
			fix_1: 'Poprawiony wniosek czeka',
			correction_2: 'Zwrócony do 2. poprawy',
			fix_2: 'Druga poprawiona wersja wniosku czeka',
			accepted: 'Formalnie przyjęty',
			refused_formal: 'Odrzucony ze względu na wymogi formalne i kwalifikowalność',
			refused: 'Niezatwierdzony przez Komisję',
			running: 'W realizacji',
			p2_registered: 'Raport końcowy został zarejestrowany',
			p2_correction_1: 'Raport końcowy został zwrócony do poprawy',
			p2_fix_1: 'Poprawiony raport końcowy oczekuje na rozpatrzenie',
			p2_refused: 'Raport końcowy został ostatecznie odrzucony',
			p2_accepted: 'Raport końcowy został przyjęty',
			p2_finished: 'Projekt jest zakończony pod względem finansowym.'
		},
		footer: {
			text: 'Euroregion Nysa<br>Fundusz Małych Projektów Turów'
		},
		settings: {
			title: 'Ustawienia',
			number: 'Numer naboru',
		},
		messages: {
			success: 'Zmiana przebiegła pomyślnie',
			error: 'Operacja nie powiodła się',
			file_uploaded: 'Plik został pomyślnie wgrany',
			deleted: 'Pozycja została usunięta',
			wrong_login: 'Konto nie istnieje lub nie zostało aktywowane w skrzynce odbiorczej e-mail.',
			pswd_not_same: 'Hasła nie są zgodne',
			pswd_empty: 'Hasło nie może być puste'
		},
		navbar: {
			users: 'Użytkownicy',
			projects: 'Projekty',
			meetings: 'Posiedzenie Komisji',
			pages: 'Strony',
			documents: 'Dokumenty',
			contacts: 'Kontakty',
			monitoring: 'Komisja Oceniająca',
			about: 'O programie',
			recipient: 'Beneficjent',
			calls: 'Nabory',
			applicant: 'Wnioskodawca'
		},
		users: {
			title: "Użytkownicy",
			name: "Imię",
			email: "E-mail",
			tel: "Telefon",
			permissions: "Uprawnienia",
			changePswd: "Zmienić hasło?",
			newPswd: "Nowe hasło",
			newPswdControl: "Potwierdzenie nowego hasła",
			role: {
				admin: "Admin",
				user1: "Wnioskodawca",
				"meetings-only": "Członek Komisji"
			}
		},
		meetings: {
			title: "Posiedzenie Komisji",
			tableDateCol: "Data posiedzenia",
			tableName: "Nazwa posiedzenia",
			addMeeting: "Dodaj posiedzenie",
			attachments_cs: 'Załączniki dla czeskich członków Komisji Oceniającej',
			attachments_pl: 'Załączniki dla polskich członków Komisji Oceniającej',
		}
	}
}


const i18n = new VueI18n({
	locale: lang,
	messages
});

export default i18n;
