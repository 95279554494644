<template>
   <transition name="message-controller__wrap-anim">
      <div v-if="messages.length > 0" class="message-controller">
         <a href="#" @click="removeAllMessages" class="message-controller__close">×</a>
         <div :key="index" v-for="(message,index) in messages" class="message-controller__item"
              :class="cssClass[index]">
            <span>{{ message }}</span>
         </div>
      </div>
   </transition>
</template>

<script>
export default {
   name: 'message-controller',
   data() {
      return {
         messages: [],
         type: [],
         cssClass: []

      }
   },
   created() {
      this.$eventHub.$on('openMessageController', this.open);
   },
   beforeDestroy() {
      this.$eventHub.$off('openMessageController');
   },
   methods: {
      open(type, message) {
         this.messages.push(message);
         this.type.push(type);


         switch (type) {
            case "success" :
               this.cssClass.push('message-controller__item--success');
               break;
            case "error" :
               this.cssClass.push('message-controller__item--error');
               break;
         }

         setTimeout(() => {
            this.messages.shift();
            this.type.shift();
            this.cssClass.shift();

         }, 5000);


      },
      removeAllMessages() {
         this.messages = [];
         this.type = [];
         this.cssClass = [];
      }

   }
}
</script>

<style lang="scss" scoped>
.message-controller {
   position: fixed;
   z-index: 1090;
   top: 0;
   margin-left: auto;
   margin-right: auto;
   left: -50px;
   max-width: 300px;
   margin-top: 8px;
   border-bottom-right-radius: 190px;
   padding: 0;
   transform: translateY(0) rotate(0deg);
   transform-origin: center bottom;
   font-family: 'Bebas Neue', cursive;
   min-height: 71px;
   font-size: 1.125rem;
   letter-spacing: .2px;
   @media (min-width: 992px) {
      margin-top: 83px;
   }

   &__item {
      position: relative;
      padding: 10px 30px 10px 70px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &--success {
         background-color: var(--success);
         color: white;
      }

      &--error {
         background-color: var(--danger);
      }
   }

   &__close {
      color: white;
      font-size: 26px;
      position: absolute;
      right: 12px;
      top: 0;

   }

   &__wrap-anim {
      &-enter-active,
      &-leave-active {

         transition: all .5s;
      }

      &-enter,
      &-leave-to {
         opacity: 0;
         transform: translateY(-400px)
      }
   }

   .glyphicon {
      font-size: 24px;
      margin-right: 9px;
      top: -2px;
   }

   .glyphicon--success {
      color: #18ff0c;
   }

   .glyphicon--error {
      color: #d63b3b;
   }

}
</style>
